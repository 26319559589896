












































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventItem, EventOptionItem } from '@/models/EventItem';
import { ReqPostEventEntry } from '@/gen/api';
Component.registerHooks(['beforeRouteEnter']);
@Component({
  components: {}
})
export default class P0243 extends Vue {
  beforeRouteEnter(to: any, from: any, next: any) {
    if (['register-event', 'event-detail'].includes(from.name)) {
      next();
    } else {
      next('/events');
    }
  }
  @Prop({ required: true }) propEventItem!: string;
  @Prop({ required: true }) propRequestBody!: string;
  // 会員区分コード
  @Prop({ required: true })
  eventFeeClassCode!: string;

  // イベント情報
  eventItem = EventItem.new(JSON.parse(this.propEventItem) as EventItem);
  // リクエストボディ
  requestBody = JSON.parse(this.propRequestBody) as ReqPostEventEntry;

  // 支払合計金額
  payAmount(): number {
    return this.requestBody.event_options.reduce((sum, item) => {
      return sum + item.pay_amount;
    }, 0);
  }
  // 選択状態のイベント内選択肢
  selectedEventOptions(): EventOptionItem[] {
    return this.eventItem.eventOptions.filter(e => e.isSelected);
  }

  // 登録完了をトラッキングするためにデータレイヤーにプッシュ
  mounted() {
    this.$dataLayer.push({
      event: 'form_completion'
    });
  }
}
